#container{
    width: 90%;
    margin-left: 5%;
    margin-top: 2%;
}

#nameCell{
    text-align: left;
    font-family: Roboto,Helvetica,Arial, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding: 16px;
    font-size: 17px;
    text-decoration: underline;
    background-color: #1976d2;
    color: white;
}

#tableCell{
    font-family: Roboto,Helvetica,Arial, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: right;
    padding: 16px;
    font-size: 17px;
    text-decoration: underline;
    background-color: #1976d2;
    color: white;
}

#filterSelect{
    width: 209px;
    padding-right: 4%;
}

#searchbar{
    width: 181px; /* +28 for true width*/
}

#searchbutton{
    margin-top: 0.5%;
    margin-bottom: 0.5%;
}

@media (max-width: 600px) {
    #container {
        margin-top: 5%;
    }
}
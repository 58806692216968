
.Typography{
    background-color: #1976d2;
    color: white;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    height: 7vh;
    padding: 10px;
    font-size: 1.3em;
}


